<script lang="ts" setup></script>

<template>
  <ButtonBase>
    <template #before>
      <Icon name="arrow-left" />
    </template>
    {{ '' }}
    <template #after>
      <Icon name="arrow-left" />
    </template>
  </ButtonBase>
</template>

<style lang="scss" scoped>
.button {
  --pv: 0;
  --ph: 0;

  @include fluid-ts-ds(--w, 38px, 54px);
  @include fluid-ts-ds(--h, 38px, 54px);

  --color-border: var(--c-secondary);
  --color-background: var(--c-secondary);
  --color-border-hover: var(--c-secondary);
  --color-hover: var(--c-blue-rollover);

  width: var(--w);
  height: var(--h);

  position: relative;
  overflow: hidden;

  :deep(svg) {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    @include transitions(opacity, left);
  }

  :deep(svg:first-child) {
    opacity: 0;
    left: calc(-1 * var(--gap));
  }
  :deep(svg:last-child) {
    left: 50%;
  }

  @include hover {
    :deep(svg:first-child) {
      opacity: 1;
      left: 50%;
    }
    :deep(svg:last-child) {
      opacity: 0;
      left: calc(100% + var(--gap));
    }
  }

  &.is-hovered {
    :deep(svg:first-child) {
      opacity: 1;
      left: 50%;
      color: var(--color-hover);
    }
    :deep(svg:last-child) {
      opacity: 0;
      left: calc(100% + var(--gap));
    }
  }
}
</style>
